import * as React from 'react';

import { ToS, PageNew } from '../../../fragments';

const ToSPage = () => {
  return (
    <PageNew title="Kokolingo" headline="Opći uvjeti">
      <ToS />
    </PageNew>
  );
};

export default ToSPage;
