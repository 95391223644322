import React from 'react';
import LazyLoad from 'react-lazyload';
import { useIntlMessages } from '@kokolingo/localisation';

import {
  SectionHeader,
  SectionIntro,
  SectionShortAbout,
  SectionVideoAbout,
  SectionSounds,
  SectionActivities,
  SectionFooter,
  SectionCustomers,
  SectionPricing,
  SectionHowToStart,
  SectionMedia,
  SectionFAQ,
  SectionInstitutions,
  SectionBlog,
  PageNew,
} from '../../../fragments';

import videos from '../../../videos';

import './index.scss';
import './sliders.scss';

const NewHome = () => {
  const messages = useIntlMessages();
  return (
    <PageNew
      title="Kokolingo"
      description={messages.meta.KokolingoDescription}
      wrapper={(props) => <div {...props} />}
      headline=""
    >
      <SectionHeader />
      <SectionHowToStart />
      <SectionIntro />
      <SectionShortAbout />
      <LazyLoad classNamePrefix="video-about" offset={100} once>
        <SectionVideoAbout videoSrc={videos.KOKOLINGO} />
      </LazyLoad>
      <LazyLoad classNamePrefix="section-sounds" offset={100} once>
        <SectionSounds />
      </LazyLoad>
      <SectionActivities />
      <SectionMedia />
      <SectionInstitutions />
      <SectionCustomers />
      <SectionPricing />
      <LazyLoad offset={100} once>
        <SectionFAQ />
      </LazyLoad>
      <LazyLoad offset={100} once>
        <SectionBlog />
      </LazyLoad>
      <LazyLoad offset={100} once>
        <SectionFooter />
      </LazyLoad>
    </PageNew>
  );
};

export default NewHome;
