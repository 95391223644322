import React from 'react';

import { PageNew, BlogLastArticles, BlogArticles } from '../../../fragments';

import './index.scss';

const NewHome = () => {
  return (
    <PageNew
      headline="BLOG"
      description="Blog posts about speech and language therapy."
      title="Kokolingo - Blog"
    >
      <BlogLastArticles />
      <BlogArticles />
    </PageNew>
  );
};

export default NewHome;
