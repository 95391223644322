import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize, useIntlMessages } from '@kokolingo/localisation';

import {
  PageNew,
  FAQassessmentItems,
  FAQnew,
  SectionMedia,
} from '../../../fragments';

import { ButtonNew, Card, Icon, Review, Text } from '../../../components';

import headerKoko from '../../../images/new-landing/header-koko.svg';
import ema from '../../../images/hr/ema.jpeg';
import mamaKornelija from '../../../images/hr/mama-kornelija.jpg';
import eglasTeam from '../../../images/eglas-team.jpg';
import slpWithChild from '../../../images/hr/slp-with-child.jpeg';
import downloadPDF from '../../../images/default/icons/download-pdf.svg';
import outcomeImage from '../../../images/kokolingo-generic1.jpg';
import reviews from '../../../images/hr/reviews.png';
import footerKoko from '../../../images/new-landing/footer-koko.svg';
import barcode from '../../../images/hr/slp-assessment-payment.png';

import emoji from '../../../constants/emoji';

import './index.scss';

const SpeechTherapyAssessment = () => {
  const messages = useIntlMessages();

  return (
    <PageNew
      headline=""
      description=""
      title="Kokolingo - logopedska procjena"
      wrapper={(props) => <div {...props} />}
    >
      <Row className="section-header assessment-header" noGutters>
        <Col
          className="section-header-title"
          md={{ offset: 1, span: 5 }}
          sm={{ offset: 1, span: 10 }}
        >
          <img src={headerKoko} alt="Koko" />
          <Text as="h1" bold>
            <Localize id="SpeechTherapyAssessment.Title" />
          </Text>
          <Text as="h3">
            <Localize id="SpeechTherapyAssessment.Description" />
          </Text>
          <ButtonNew onClick={() => {}}>
            <Localize id="SpeechTherapyAssessment.CTA" />{' '}
            {emoji.POINTING_FINGER}
          </ButtonNew>
        </Col>
        <Col
          className="section-header-hero assessment-header-hero"
          md={{ offset: 1, span: 4 }}
          xs={{ offset: 0, span: 10 }}
        >
          <img src={ema} alt="" />
          <Review img={mamaKornelija} from="mamaKornelija" />
        </Col>
      </Row>

      <Row
        className="intro assessment-intro justify-content-center align-items-center"
        noGutters
      >
        <Col md={{ offset: 0, span: 4 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3">
            <Localize id="SpeechTherapyAssessment.SectionIntro.Title" />
          </Text>
          <Text>
            <Localize id="SpeechTherapyAssessment.SectionIntro.Description" />
          </Text>
        </Col>
        <Col md={{ offset: 1, span: 5 }} xs={{ offset: 0, span: 8 }}>
          <img
            src={eglasTeam}
            alt=""
            className="section-intro-team"
            style={{ borderRadius: '50%' }}
          />
        </Col>
      </Row>

      <Row className="short-about therapy-assessment" noGutters>
        <Col xs={{ offset: 1, span: 10 }}>
          <Row className="short-about-cards">
            <Col xs={12} sm={6} md={4}>
              <Card>
                <Card.Badge>
                  <Icon name="checklistTime" />
                </Card.Badge>
                <Text as="h3">
                  <Localize id="SpeechTherapyAssessment.SectionShortAbout.Title1" />
                </Text>
                <Text>
                  <Localize id="SpeechTherapyAssessment.SectionShortAbout.Description1" />
                </Text>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card>
                <Card.Badge>
                  <Icon name="home" />
                </Card.Badge>
                <Text as="h3">
                  <Localize id="SpeechTherapyAssessment.SectionShortAbout.Title2" />
                </Text>
                <Text>
                  <Localize id="SpeechTherapyAssessment.SectionShortAbout.Description2" />
                </Text>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card>
                <Card.Badge>
                  <Icon name="like2" />
                </Card.Badge>
                <Text as="h3">
                  <Localize id="SpeechTherapyAssessment.SectionShortAbout.Title3" />
                </Text>
                <Text>
                  <Localize id="SpeechTherapyAssessment.SectionShortAbout.Description3" />
                </Text>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        className="intro assessment-how-to-start align-items-center justify-content-center"
        noGutters
      >
        <Col
          className="mb-5 mb-md-0"
          xs={{ offset: 0, span: 8 }}
          md={{ offset: 0, span: 4 }}
        >
          <img
            src={slpWithChild}
            alt=""
            width="100%"
            style={{ borderRadius: '50%' }}
          />
        </Col>
        <Col
          className="d-flex flex-column justify-content-center"
          xs={{ offset: 0, span: 10 }}
          md={{ offset: 1, span: 5 }}
        >
          <Text as="h2" bold>
            <Localize id="SpeechTherapyAssessment.SectionHowToStart.Title" />
          </Text>
          <ol>
            <li>
              <Text>
                <Localize id="SpeechTherapyAssessment.SectionHowToStart.Note1" />
              </Text>
              <ButtonNew onClick={() => {}} size="small" className="mt-1">
                <Localize id="SpeechTherapyAssessment.CTA" />{' '}
                {emoji.POINTING_FINGER}
              </ButtonNew>
            </li>
            <li>
              <Text>
                <Localize id="SpeechTherapyAssessment.SectionHowToStart.Note2" />
              </Text>
              <br />
              <img src={barcode} alt="" className="mt-3" />
            </li>
            <li>
              <Text>
                <Localize id="SpeechTherapyAssessment.SectionHowToStart.Note3" />
              </Text>
            </li>
          </ol>
          <ButtonNew className="mt-4" onClick={() => {}}>
            <Localize id="SpeechTherapyAssessment.CTA" />{' '}
            {emoji.POINTING_FINGER}
          </ButtonNew>
        </Col>
      </Row>

      <Row
        className="intro align-items-center justify-content-center"
        noGutters
      >
        <Col xs={{ offset: 0, span: 10 }}>
          <Text as="h2" bold center>
            <Localize id="SpeechTherapyAssessment.SectionInfo.Title" />
          </Text>
          <Text as="p" className="text-justify">
            <Localize id="SpeechTherapyAssessment.SectionInfo.Description" />
          </Text>
        </Col>
      </Row>

      <Row
        id="upute"
        className="intro align-items-center justify-content-center"
        noGutters
      >
        <Col
          className="d-flex justify-content-center order-md-2"
          md={{ offset: 1, span: 3 }}
          xs={{ offset: 0, span: 6 }}
        >
          <iframe
            className="section-instructions-video"
            src="https://www.youtube.com/embed/BI50B8E4JgA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </Col>
        <Col
          className="order-md-1"
          md={{ offset: 0, span: 6 }}
          xs={{ offset: 0, span: 10 }}
        >
          <Text as="h3">
            <Localize id="SpeechTherapyAssessment.SectionInstructions.Title" />
          </Text>
          <Text as="p" className="text-justify">
            <Localize
              id="SpeechTherapyAssessment.SectionInstructions.Description"
              values={{
                button: (
                  <a
                    href="logopedska-procjena/upute.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ButtonNew className="p-0 mt-3" size="small">
                      <Row className="justify-content-center align-items-center">
                        <Col xs={{ offset: 0, span: 2 }}>
                          <img src={downloadPDF} alt="" height="50px" />
                        </Col>
                        <Col xs={{ offset: 0, span: 8 }}>
                          <Localize id="SpeechTherapyAssessment.SectionInstructions.DownloadPDF" />
                        </Col>
                      </Row>
                    </ButtonNew>
                  </a>
                ),
              }}
            />
          </Text>
        </Col>
      </Row>

      <Row
        className="intro assessment-outcome align-items-center justify-content-center"
        noGutters
      >
        <Col
          className="mb-5 mb-md-0"
          xs={{ offset: 0, span: 8 }}
          md={{ offset: 0, span: 4 }}
        >
          <img
            src={outcomeImage}
            alt=""
            width="100%"
            style={{ borderRadius: '50%' }}
          />
        </Col>
        <Col
          className="d-flex flex-column justify-content-center"
          xs={{ offset: 0, span: 10 }}
          md={{ offset: 1, span: 5 }}
        >
          <Text as="h2" bold center>
            <Localize id="SpeechTherapyAssessment.SectionOutcome.Title" />
          </Text>
          <Text as="p" className="text-justify">
            <Text as="h4" bold>
              <Localize id="SpeechTherapyAssessment.SectionOutcome.Subtitle1" />
            </Text>
            <Localize id="SpeechTherapyAssessment.SectionOutcome.Description1" />
            <br />
            <br />
            <Text as="h4" bold>
              <Localize id="SpeechTherapyAssessment.SectionOutcome.Subtitle2" />
            </Text>
            <Localize id="SpeechTherapyAssessment.SectionOutcome.Description2" />
          </Text>
        </Col>
      </Row>

      <SectionMedia />

      <Row className="section-faq-assessment" noGutters>
        <Col md={{ offset: 2, span: 8 }} xs={{ offset: 1, span: 10 }}>
          <Text as="h2" bold center>
            <Localize id="homePage.SectionFAQ.Title" />
          </Text>
          <FAQnew answers={FAQassessmentItems} />
        </Col>
      </Row>

      <Row className="section-footer assessment-footer" noGutters>
        <Col
          className="section-footer-content order-1 order-md-0"
          xs={12}
          md={{ offset: 2, span: 5 }}
        >
          <Col xs={{ span: 9 }} md={{ span: 12 }}>
            <Text as="h3" className="footer-title" light bold color="light">
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: messages.SpeechTherapyAssessment.SectionFooter.Title,
                }}
              />
            </Text>
          </Col>
          <Text color="light">
            <div className="pricing-grid-container my-2">
              <div className="subscription-price__value__label">
                <Localize id="pricing.FullPrice" />
              </div>
              <div className="subscription-price__value__label">
                <Localize id="pricing.FullPrice" />
              </div>
              <div className="subscription-price__value assessment-price-value text--red">
                <p>
                  <Localize
                    id="SpeechTherapyAssessment.PriceFullEuros"
                    values={{
                      span: (priceOld) => (
                        <span className="subscription-price--new">
                          {priceOld}
                        </span>
                      ),
                      space: ' ',
                    }}
                  />
                </p>
              </div>
              <div className="subscription-price__value assessment-price-value">
                <p
                  style={{
                    position: 'absolute',
                    right: '0',
                    bottom: '0',
                  }}
                >
                  <Localize
                    id="SpeechTherapyAssessment.PriceFull"
                    values={{
                      span: (priceNew) => (
                        <span className="subscription-price--new font-weight-light">
                          {priceNew}
                        </span>
                      ),
                      space: ' ',
                    }}
                  />
                </p>
              </div>
            </div>
            <br />
            <Localize id="SpeechTherapyAssessment.SectionFooter.Description" />
            <br />
            <Row className="align-items-center justify-content-center">
              <Col xs={{ offset: 0, span: 7 }} lg={{ offset: 0, span: 8 }}>
                <ButtonNew onClick={() => {}} className="mt-3">
                  <Localize id="SpeechTherapyAssessment.CTA" />{' '}
                  {emoji.POINTING_FINGER}
                </ButtonNew>
              </Col>
              <Col
                className="assessment-reviews d-flex flex-column justify-content-start align-items-center"
                lg={{ offset: 0, span: 4 }}
                md={{ offset: 0, span: 5 }}
                sm={{ offset: 0, span: 4 }}
                xs={{ offset: 0, span: 5 }}
              >
                <a
                  className="d-flex flex-column justify-content-start align-items-center"
                  href="https://www.google.com/search?q=kokolingo#lrd=0x4764a771252c059f:0x3c2d850bb3e8c1fb,1,,,,"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={reviews} alt="" />
                  <span>40 Google recenzija</span>
                </a>
              </Col>
            </Row>
          </Text>
        </Col>
        <Col
          className="section-footer-logo assessment-footer-logo order-0 order-md-1"
          sm={12}
          md={{ offset: 1, span: 3 }}
        >
          <img src={footerKoko} alt="" />
        </Col>
      </Row>
    </PageNew>
  );
};

export default SpeechTherapyAssessment;
